import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Header({ LogoWhite, LogoBlack, AsideOpen }) {

   useEffect(() => {
      const header = document.getElementById("header")
      header.addEventListener("mouseover", () => {
         header.classList.add("on")
      })
      header.addEventListener("mouseout", () => {
         header.classList.remove("on")
      })
   }, [])

   return (
      <header id="header">
         <nav id="gnb">
            <ul className="gnb_ul">
               <li>
                  <h1>
                     <Link to="/">
                        <img className="logo black" src={LogoBlack} alt="logo" />
                        <img className="logo white" src={LogoWhite} alt="logo" />
                     </Link>
                  </h1>
               </li>
               <li className="gnb_item_category">
                  <ul className="category_wrap">
                     <li className="item">
                        <Link className="category" to="/company/about">COMPANY</Link>
                        <ul className="gnb_sub">
                           <li>
                              <Link className="a_btn" to="/company/about">About ZeaTech</Link>
                           </li>
                           <li>
                              <Link className="a_btn" to="/company/history">연혁</Link>
                           </li>
                           <li>
                              <Link className="a_btn" to="/company/contact-us">오시는 길</Link>
                           </li>
                        </ul>
                     </li>
                     <li className="item">
                        <Link className="category" to="/product">PRODUCT</Link>
                     </li>
                     <li className="item">
                        <Link className="category" to="/customer/notice">CUSTOMER</Link>
                        <ul className="gnb_sub">
                           <li>
                              <Link className="a_btn" to="/customer/notice">공지사항</Link>
                           </li>
                           <li>
                              <Link className="a_btn" to="/customer/data">자료실</Link>
                           </li>
                        </ul>
                     </li>
                     <li className="item">
                        <Link className="category" to="/contact/faq">CONTACT</Link>
                        <ul className="gnb_sub">
                           <li>
                              <Link className="a_btn" to="/contact/faq">자주묻는 질문</Link>
                           </li>
                           <li>
                              <Link className="a_btn" to="/contact/inquire">문의하기</Link>
                           </li>
                        </ul>
                     </li>
                  </ul>
               </li>
               <li>
                  <button onClick={AsideOpen}>
                     <i className="gnb_icon xi-align-right white"></i>
                  </button>
               </li>
            </ul>
         </nav>
      </header>
   )
}