import { Link, useLocation } from "react-router-dom";

const useQuery = () => {
   return new URLSearchParams(useLocation().search);
};

export default function DetailControl({ navigationData, listUrl, contextType }) {

   const query = useQuery();
   const page = query.get('page');
   const search = query.get('search');
   const order = query.get('order');
   const order2 = query.get('order2');
   const order3 = query.get('order3');
   const activeBtn01 = query.get('activeBtn01');
   const activeBtn02 = query.get('activeBtn02');
   const activeBtn03 = query.get('activeBtn03');

   const getLink = (type, id, title, isNext) => {
      const basePath = {
         notice: `/customer/notice/detail/${id}?index=${id}&page=${page}&search=${search}`,
         data: `/customer/data/detail/${id}?index=${id}&page=${page}&search=${search}`,
         product: `/product/detail/${id}?index=${id}&page=${page}&order=${order}&order2=${order2}&order3=${order3}&search=${search}&activeBtn01=${activeBtn01}&activeBtn02=${activeBtn02}&activeBtn03=${activeBtn03}`
      }[type];

      return (
         <Link to={basePath} className="btn">
            {isNext ? (
               <>
                  <p className="tit fs_type_11 fw_type_light">{title}</p>
                  <div className="next_wrap">
                     <p className="fs_type_11 fw_type_04">NEXT</p>
                     <i className="xi-long-arrow-right fs_type_05"></i>
                  </div>
               </>
            ) : (
               <>
                  <div className="prev_wrap">
                     <i className="xi-long-arrow-left fs_type_05"></i>
                     <p className="fs_type_11 fw_type_04">PREV</p>
                  </div>
                  <p className="tit fs_type_11 fw_type_light">{title}</p>
               </>
            )}
         </Link>
      );
   };

   const getPrevNextButton = (type, id, title, isNext) => {
      const noLinkClass = isNext ? "next_wrap" : "prev_wrap";
      const noLinkIcon = isNext ? "xi-long-arrow-right" : "xi-long-arrow-left";
      const noLinkText = isNext ? "NEXT" : "PREV";

      return id == null ? (
         <div className="btn">
            {isNext ? (
               <>
                  <p className="tit fs_type_11 fw_type_light">{title}</p>
                  <div className={`${noLinkClass} noitem`}>
                     <p className="fs_type_11 fw_type_04">{noLinkText}</p>
                     <i className={`${noLinkIcon} fs_type_05`}></i>
                  </div>
               </>
            ) : (
               <>
                  <div className={`${noLinkClass} noitem`}>
                     <i className={`${noLinkIcon} fs_type_05`}></i>
                     <p className="fs_type_11 fw_type_04">{noLinkText}</p>
                  </div>
                  <p className="tit fs_type_11 fw_type_light">{title}</p>
               </>
            )}
         </div>
      ) : (
         getLink(type, id, title, isNext)
      );
   };

   return (
      <div className="control_wrap type_01 wrap">
         {getPrevNextButton(contextType, navigationData.prev, navigationData.prevTitle, false)}
         {contextType === "product" ? (
            <Link className="btn_list" to={`${listUrl}?order=${order}&order2=${order2}&order3=${order3}&page=${page}&search=${search}&activeBtn01=${activeBtn01}&activeBtn02=${activeBtn02}&activeBtn03=${activeBtn03}`}>
               <i className="xi-apps fs_type_05 icon"></i>
            </Link>
         ) : (
            <Link className="btn_list" to={`${listUrl}?page=${page}&search=${search}`}>
               <i className="xi-apps fs_type_05 icon"></i>
            </Link>
         )
         }
         {getPrevNextButton(contextType, navigationData.next, navigationData.nextTitle, true)}
      </div>
   );
}
