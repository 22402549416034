import { useEffect } from "react";

export default function HeaderActive() {
   useEffect(() => {
      const header = document.getElementById("header")

      header.classList.add("on");
      header.classList.add("active");
      return () => {
         header.classList.remove("on");
         header.classList.remove("active");
      };
   }, []);
}