import { Link } from "react-router-dom"

// images Start
import SNSIcon_01 from "../images/sns_icon_01.webp"
import SNSIcon_02 from "../images/sns_icon_02.webp"
import SNSIcon_03 from "../images/sns_icon_03.webp"
import SNSIcon_04 from "../images/sns_icon_04.webp"
import SNSIcon_05 from "../images/sns_icon_05.webp"
// images End

export default function Footer({ LogoWhite }) {
   return (
      <footer id="footer">
         <div className="wrap">
            <div className="dep_01 item_top">
               <div className="txt_wrap">
                  <img className="logo" src={LogoWhite} alt="로고" />
                  <div className="item_01">
                     <p className="txt fs_type_09"><strong>ZeaTech</strong></p>
                     <p className="txt lh_type_03 fs_type_09">B Dong 108, U-TOWER, Sinsu-Ro, Suji-Gu,Kyunggi-Do, 16827 Republic of Korea</p>
                  </div>
                  <div>
                     <p className="txt fs_type_09">CEO : 김지활 KIM JI HWAL</p>
                     <p className="txt fs_type_09">PHONE : 82-31-266-1810</p>
                     <p className="txt fs_type_09">EMAIL : info@zeatech.co.kr</p>
                  </div>
               </div>
               <ul className="ft_gnb_wrap">
                  <li className="item">
                     <ul>
                        <li className="dep_02_item">
                           <h4 className="tit point">COMPANY</h4>
                        </li>
                        <li className="dep_02_item">
                           <Link className="a_btn" to="/company/about">About ZeaTech</Link>
                        </li>
                        <li className="dep_02_item">
                           <Link className="a_btn" to="/company/history">연혁</Link>
                        </li>
                        <li className="dep_02_item">
                           <Link className="a_btn" to="/company/contact-us">오시는 길</Link>
                        </li>
                     </ul>
                  </li>
                  <li className="item">
                     <ul>
                        <li className="dep_02_item">
                           <h4 className="tit point">PRODUCT</h4>
                        </li>
                        <li className="dep_02_item">
                           <Link className="a_btn" to="/product">PRODUCT</Link>
                        </li>
                     </ul>
                  </li>
                  <li className="item">
                     <ul>
                        <li className="dep_02_item">
                           <h4 className="tit point">CUSTOMER</h4>
                        </li>
                        <li className="dep_02_item">
                           <Link className="a_btn" to="/customer/notice">공지사항</Link>
                        </li>
                        <li className="dep_02_item">
                           <Link className="a_btn" to="/customer/data">자료실</Link>
                        </li>
                     </ul>
                  </li>
                  <li className="item">
                     <ul>
                        <li className="dep_02_item">
                           <h4 className="tit point">CONTACT</h4>
                        </li>
                        <li className="dep_02_item">
                           <Link className="a_btn" to="/contact/faq">자주묻는 질문</Link>
                        </li>
                        <li className="dep_02_item">
                           <Link className="a_btn" to="/contact/inquire">문의하기</Link>
                        </li>
                     </ul>
                  </li>
               </ul>
            </div>
            <div className="dep_01 item_bottom">
               <small className="copy">COPYRIGHT © 2024 ZeaTech ALL RIGHTS RESERVED.</small>
               <ul className="sns_wrap">
                  <li className="sns_item">
                     <Link to="">
                        <img className="icon" src={SNSIcon_01} alt="SNS 아이콘" />
                     </Link>
                  </li>
                  <li className="sns_item">
                     <Link to="">
                        <img className="icon" src={SNSIcon_02} alt="SNS 아이콘" />
                     </Link>
                  </li>
                  <li className="sns_item">
                     <Link to="">
                        <img className="icon" src={SNSIcon_03} alt="SNS 아이콘" />
                     </Link>
                  </li>
                  <li className="sns_item">
                     <Link to="">
                        <img className="icon" src={SNSIcon_04} alt="SNS 아이콘" />
                     </Link>
                  </li>
                  <li className="sns_item">
                     <Link to="">
                        <img className="icon" src={SNSIcon_05} alt="SNS 아이콘" />
                     </Link>
                  </li>
               </ul>
            </div>
         </div>
      </footer>
   )
}