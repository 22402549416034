export default function PageControl({ data, setPageLink, activeIndex, setActiveIndex }) {

   const prevClick = () => {
      if (data.list.current_page > 1) {
         setPageLink(data.list.current_page - 1);
         setActiveIndex(activeIndex - 1)
      }
   }
   const nextClick = () => {
      if (data.list.current_page < data.list.last_page) {
         setPageLink(data.list.current_page + 1);
         setActiveIndex(activeIndex + 1)
      }
   }
   const handleClick = (label, index) => {
      setPageLink(label)
      setActiveIndex(index)
   }

   return (
      <ul className="page_control_wrap type_01 wrap mb_120">
         {
            data.list.links.map((links, index) => (
               links.label === "&laquo; Previous" ? (
                  <li key={index}>
                     <button onClick={() => { prevClick() }} className="control_btn">
                        <i className="xi-angle-left"></i>
                     </button>
                  </li>
               ) : links.label === "Next &raquo;" ? (
                  <li key={index}>
                     <button onClick={() => { nextClick() }} className="control_btn">
                        <i className="xi-angle-right"></i>
                     </button>
                  </li>
               ) : (
                  <li key={index}>
                     <button onClick={() => { handleClick(links.label, index) }} className={`control_btn pagination ${index === parseInt(activeIndex) ? "on" : ""}`}>{links.label}</button>
                  </li>
               )
            ))
         }
      </ul>
   )
}