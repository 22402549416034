import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import DOMPurify from "dompurify";
// import NProgress from 'nprogress';

// components Start
import SubPageMb from "../../components/SubPageMb";
import PageControl from "../../components/PageControl";
// components End

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export default function Data() {
    const query = useQuery();
    const page = query.get('page');
    const search = query.get('search');

    const [data, setData] = useState();
    const [pageLink, setPageLink] = useState(page ? page : 1);

    const [searchTerm, setSearchTerm] = useState(search ? search : '');
    const [searchTermWrap, setSearchTermWrap] = useState(search ? search : '');
    const [activeIndex, setActiveIndex] = useState(page ? page : 1);

    const [loading, setLoading] = useState(false);
    // loading ? NProgress.start() : NProgress.done();

    const navigate = useNavigate();

    const handleChange = (event) => {
        setSearchTermWrap(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const regex = /^[a-zA-Z0-9가-힣\s]*$/;

        if (!regex.test(searchTermWrap)) {
            alert("영문[대소문자], 한글[자음+모음], 숫자로 입력해주세요");
            return;
        }

        setActiveIndex(1);
        setPageLink(1);
        setSearchTerm(searchTermWrap);
        navigate(`?page=${1}&search=${searchTermWrap}`);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/data/list?page=${pageLink}&search=${searchTerm}`);
                setData(response.data.data);
                if (response.data.data.state === false) {
                    alert("일시적인 오류가 발생했습니다.");
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [searchTerm, pageLink]);

    useEffect(() => {
        navigate(`?page=${pageLink}&search=${searchTerm}`);
    }, [searchTerm, pageLink, navigate]);

    return (
        <div className="customer_data customer_data_main customer sub_page">
            <SubPageMb
                title="CUSTOMER"
                navDep_01="CUSTOMER"
                navDep_02="자료실"
            />
            <div className="wrap">
                <div className="top_nav type_01">
                    <h3 className="fs_type_12 tit">자료실</h3>
                    <form className="search_from" onSubmit={handleSubmit}>
                        <fieldset>
                            <legend className="hide">검색</legend>
                            <div className="search_box">
                                <input
                                    id="search"
                                    type="search"
                                    name="search"
                                    placeholder="검색어를 입력하세요"
                                    value={searchTermWrap}
                                    onChange={handleChange}
                                />
                                <button onClick={handleSubmit} type="button">
                                    <i className="xi-search icon_search" />
                                </button>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <ul className="list_wrap">
                    {
                        loading ? (
                            <div className="icon_loading_wrap">
                                <i className="xi-spinner-2 xi-spin icon_loading"></i>
                            </div>
                        ) : (
                            data ? (
                                data.total === 0 ? (
                                    <li className="item noitem txt_align_center">게시글이 없습니다.</li>
                                ) : (
                                    data.data.map((data, index) => (
                                        <li key={index} className="item">
                                            <Link to={`detail/${data.id}?index=${data.id}&page=${pageLink}&search=${searchTerm}`} className="a_btn">
                                                <img src={data.file} className="thumbnail pc" alt="" />
                                                <div className="text_wrap">
                                                    <h4 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.title) }} className="tit lh_type_03 fs_type_06 fw_type_03"></h4>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: DOMPurify.sanitize(data.content, {
                                                               ALLOWED_TAGS: ['p', 'div', 'a', 'span'],
                                                               ALLOWED_ATTR: []
                                                            })
                                                         }}
                                                        className="txt lh_type_07 fs_type_08 mt_24"
                                                    >
                                                    </div>
                                                </div>
                                                <div className="icon_wrap type_01">
                                                    <i className="icon xi-angle-right fs_type_11"></i>
                                                </div>
                                            </Link>
                                        </li>
                                    ))
                                )
                            ) : (
                                <div className="icon_loading_wrap">
                                    <i className="xi-spinner-2 xi-spin icon_loading"></i>
                                </div>
                            )
                        )
                    }
                </ul>
                {data &&
                    <PageControl
                        data={data}
                        pageLink={pageLink}
                        setPageLink={setPageLink}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                    />}
            </div>
        </div>
    );
}
