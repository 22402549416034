import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import axios from 'axios';
import DOMPurify from 'dompurify';

// components Start
import PrivacyAgreement from "../components/PrivacyAgreement";
import Popup from "../components/Popup";
// components End

// css Start
import 'swiper/css';
import 'swiper/css/navigation';
// css End

// images Start
import IconAbout from "../images/home/icon_about.webp"
import IconContact from "../images/home/icon_contact.webp"
import IconContactUs from "../images/home/icon_contact_us.webp"
import Zeatech from "../images/home/zeatech.webp"
import ZeatechMv from "../images/home/zeatech_mv.webp"
import section_04 from "../images/home/section04.webp"
import PartnersLogo_01 from "../images/home/partners_logo_01.webp"
import PartnersLogo_02 from "../images/home/partners_logo_02.webp"
import PartnersLogo_03 from "../images/home/partners_logo_03.webp"
import PartnersLogo_04 from "../images/home/partners_logo_04.webp"
import PartnersLogo_06 from "../images/home/partners_logo_06.webp"
import PartnersLogo_07 from "../images/home/partners_logo_07.webp"
// images End

export default function Home() {

   const inputBoxes = document.querySelectorAll('.input_box');

   inputBoxes.forEach((inputBox) => {
      inputBox.addEventListener("input", (event) => {
         const inputText = event.target.value;
         const nextElement = inputBox.nextElementSibling;

         if (nextElement) {
            if (inputText.length > 0) {
               nextElement.style.display = "none";
            } else {
               nextElement.style.display = "block";
            }
         }
      });
   });

   const [privacyShow, setPrivacyShow] = useState(false);
   const openPrivacyAgreement = () => {
      setPrivacyShow(true)
   }
   const closePrivacy = () => {
      setPrivacyShow(false)
   }

   // 폼 데이터 전송 Start
   const [formData, setFormData] = useState({
      name: '',
      phone: '',
      email: '',
      inquiry_content: '',
      consent: '0',
   });

   const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
         ...formData,
         [name]: value,
      });
   };
   const handleSubmit = (e) => {
      e.preventDefault();
      if (formData.consent !== '1') {
         alert('개인정보 수집 및 이용에 동의해야 합니다.');
         return;
      }
      axios.post(`${process.env.REACT_APP_API_URL}/api/registration/inquiry`, formData)
         .then(response => {
            if (response.data.state) {
               alert('문의가 등록되었습니다.');
               window.location.reload();
            } else {
               alert(response.data.msg)
            }
         })
         .catch(error => {
            console.error('There was an error!', error);
            alert('문의 등록에 실패했습니다.');
         });
   };
   // 폼 데이터 전송 End

   // data Start
   const [data, setData] = useState(null);
   const [error, setError] = useState(null);
   const windowWidth = document.body.clientWidth;

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/main`);
            setData(response.data.data);
         } catch (error) {
            // console.error('데이터 오류:', error);
            setError(error);
         }
      };

      fetchData();
   }, []);

   if (error) return <div>Error: {error.message}</div>;
   // data End

   return (
      <main id="home">
         {data && <Popup data={data} />}
         <div className="mb_swiper_wrap">
            {data ? (
               <Swiper
                  autoplay={{
                     delay: 4000,
                     disableOnInteraction: false,
                  }}
                  navigation={{
                     nextEl: '.swiper_01 .swiper-button-next',
                     prevEl: '.swiper_01 .swiper-button-prev',
                  }}
                  loop={true}
                  modules={[Autoplay, Navigation]}
                  className="mySwiper swiper_01"
               >
                  {data.banner.map((banner, index) => (
                     <SwiperSlide key={index}>
                        <div className="tit_wrap">
                           <h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(windowWidth >= 768 ? banner.pc_title : banner.mobile_title) }} className="tit Montserrat fs_type_15 fw_type_04"></h2>
                           <h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(windowWidth >= 768 ? banner.pc_sub_title : banner.mobile_sub_title) }} className="lh_type_04 fs_type_06 fw_type_01 mb_40"></h2>
                        </div>
                        {
                           windowWidth >= 768 ? (
                              banner.pc_file_type === 1
                                 ? <video className="main_banner" muted autoPlay loop playsInline> <source src={banner.pc_file} type="video/mp4" /> </video>
                                 : <img className="main_banner" src={banner.pc_file} alt="배너이미지" />
                           ) : (
                              banner.mobile_file_type === 1
                                 ? <video className="main_banner" muted autoPlay loop playsInline> <source src={banner.mobile_file} type="video/mp4" /> </video>
                                 : <img className="main_banner" src={banner.mobile_file} alt="배너이미지" />
                           )
                        }
                     </SwiperSlide>
                  ))
                  }
                  <div className="mb_swiper_nav_wrap">
                     <div className="swiper-button-prev type_01"></div>
                     <div className="swiper-button-next type_01"></div>
                  </div>
               </Swiper>
            ) : (
               <div className="loading">Loading ...</div>
            )
            }
         </div>
         <section className="section_01">
            <div className="item_01">
               <h2 className="tit fs_type_01 fw_type_04 Montserrat">ZeaTech</h2>
               <p className="txt lh_type_05 fs_type_08 fw_type_01">
                  High Perfomance PPE for Industry & Ourdoor <br className="pc" />
                  Heat & Flame Resistant Technical Textiles <br className="pc" />
                  Advanced Materials
               </p>
            </div>
            <ul className="item_02">
               <li className="sub">
                  <Link to="/company/about" className="contents_wrap">
                     <img className="icon" src={IconAbout} alt="회사소개 아이콘" />
                     <p className="fs_type_07 fw_type_04">회사소개</p>
                  </Link>
               </li>
               <li className="sub">
                  <Link to="/company/contact-us" className="contents_wrap">
                     <img className="icon" src={IconContact} alt="오시는 길 아이콘" />
                     <p className="fs_type_07 fw_type_04">오시는 길</p>
                  </Link>
               </li>
               <li className="sub">
                  <Link to="/contact/inquire" className="contents_wrap">
                     <img className="icon" src={IconContactUs} alt="문의하기 아이콘" />
                     <p className="fs_type_07 fw_type_04">문의하기</p>
                  </Link>
               </li>
            </ul>
         </section>
         <section className="section_02">
            <div className="item_01 wrap">
               <div className="tit_wrap">
                  <h3 className="sub_tit mb_16">High Peformance Technical Textiles</h3>
                  <img className="zeatech_img" src={Zeatech} alt="" />
                  <Link to="/company/about" className="btn_a type_01 pc">
                     <p className="txt">Read More</p>
                     <i className="xi-long-arrow-right"></i>
                  </Link>
               </div>
               <div className="txt_wrap">
                  <h3 className="tit fs_type_02 lh_type_01">
                     안전과 건강을 핵심 가치로써 <br />
                     지속가능한 성장을 추구합니다.
                  </h3>
                  <p className="lh_type_05 fs_type_08 fw_type_01">
                     Safety as a Core Value.<br />
                     안전과 건강은 아무리 강조해도 지나침이 없습니다. <br className="pc" />
                     이 가치를 지키기 위해, 최적의 품질을 추구하겠습니다.
                  </p>
                  <Link to="/company/about" className="btn_a type_01 mo">
                     <p className="txt">Read More</p>
                     <i className="xi-long-arrow-right"></i>
                  </Link>
               </div>
            </div>
            <img className="wrap mv" src={ZeatechMv} alt="제아테크 메인비쥬얼" />
         </section>
         <section className="section_03">
            <div>
               <h2 className="tit_01 Montserrat tit type_01">PRODUCT</h2>
               <h3 className="tit_02 Montserrat tit type_02"><span className="point">P</span>RODUCT</h3>
               <p className="txt mt_24">가장 인기있는 제품들을 만나보세요.</p>
            </div>
            <div className="swiper_02_nav_wrap wrap mb_40">
               <div className="swiper-button-prev type_01">
                  <i className="xi-angle-left fs_type_08 fw_type_03" />
                  <p className="fs_type_07 fw_type_03">PREV</p>
               </div>
               <div className="swiper-button-next type_01">
                  <p className="fs_type_07 fw_type_03">NEXT</p>
                  <i className="xi-angle-right fs_type_08 fw_type_03" />
               </div>
            </div>
            {data ? (

               <Swiper
                  autoplay={{
                     delay: 2500,
                     disableOnInteraction: false,
                  }}
                  slidesPerView={'auto'}
                  centeredSlides={true}
                  navigation={{
                     nextEl: '.section_03 .swiper-button-next',
                     prevEl: '.section_03 .swiper-button-prev',
                  }}
                  loop={true}
                  modules={[Autoplay, Navigation]}
                  className="swiper_02"
               >
                  {data.product.map((product, index) => (
                     <SwiperSlide key={index} data-id={product.id}>
                        <div className="txt_wrap">
                           <h4 className="tit fs_type_05 mb_16">{product.product_name}</h4>
                           <div className="sub_txt_wrap">
                              <p className="txt lh_type_05 fs_type_08 fw_type_01 fw_type_02">{product.topCategory}</p>
                              <p className="txt lh_type_05 fs_type_08 fw_type_01 fw_type_02">{product.midCategory}</p>
                           </div>
                        </div>
                        <div className="product_img_wrap">
                           <img className="product_img" src={product.file} alt="" />
                        </div>
                     </SwiperSlide>
                  ))}
               </Swiper>
            ) : (
               <div className="loading">Loading...</div>
            )}
         </section>
         <section className="section_04">
            <div className="wrap">
               <h3 className="tit fs_type_16 lh_type_09">
                  지속가능한<br className="mo" /> 안전, 건강, 환경 보전에 집중하여<br />
                  함께 성장하겠습니다.
               </h3>
               <img className="mv" src={section_04} alt="Health and Hygiene, Sustainability, Pollution Prevention, Industrial Safety, Carbon Zero" />
            </div>
         </section>
         <section className="section_05">
            <div>
               <h2 className="tit_01 Montserrat tit type_01">PARTNERS</h2>
               <h3 className="tit_02 Montserrat tit type_02">
                  <span className="point">P</span>ARTNERS
               </h3>
               <p className="txt mt_24 mb_60">제아테크와 함께하는 파트너사입니다.</p>
            </div>
            <ul className="list_wrap wrap">
               <li className="item">
                  <Link to="https://www.bullard.com" target="_blank">
                     <img className="logo" src={PartnersLogo_07} alt="파트너 로고" />
                  </Link>
               </li>
               <li className="item">
                  <Link to="https://crewsafe.com" target="_blank">
                     <img className="logo" src={PartnersLogo_03} alt="파트너 로고" />
                  </Link>
               </li>
               <li className="item">
                  <Link to="https://global.danner.com" target="_blank">
                     <img className="logo" src={PartnersLogo_04} alt="파트너 로고" />
                  </Link>
               </li>
               <li className="item">
                  <Link to="https://www.foxfury.com" target="_blank">
                     <img className="logo" src={PartnersLogo_02} alt="파트너 로고" />
                  </Link>
               </li>
               <li className="item">
                  <Link to="https://www.rothco.com" target="_blank">
                     <img className="logo" src={PartnersLogo_01} alt="파트너 로고" />
                  </Link>
               </li>
               <li className="item">
                  <Link to="https://www.seewayglove.com" target="_blank">
                     <img className="logo" src={PartnersLogo_06} alt="파트너 로고" />
                  </Link>
               </li>
            </ul>
         </section>
         <section className="section_06 wrap">
            <div>
               <h2 className="tit_01 Montserrat tit type_01">NOTICE</h2>
               <h3 className="tit_02 Montserrat tit type_02">
                  <span className="point">N</span>OTICE
               </h3>
               <p className="txt mt_24 mb_60">제아테크의 소식을 알려드립니다.</p>
               <Link to="/customer/notice" className="btn_a type_01 pc">
                  <p className="txt">Read More</p>
                  <i className="xi-long-arrow-right"></i>
               </Link>
            </div>
            <ul className="list_wrap">
               {data ? (
                  data.notice.slice(0, 5).map((notice, index) => (
                     <li className="notice_item" key={index} data-id={notice.id}>
                        <Link to={`/customer/notice/detail/${notice.id}?index=${notice.id}&page=1&search=`} className="btn">
                           <h4 className="tit fs_type_06 fw_type_03">{notice.title}</h4>
                           <p className="fs_type_08 txt">{notice.created_at}</p>
                        </Link>
                     </li>
                  ))
               ) : (
                  <div className="loading">Loading ...</div>
               )
               }
            </ul>
         </section>
         <section className="section_7">
            <div className="wrap">
               <h2 className="tit_01 Montserrat tit type_01">INQUIRY</h2>
               <h3 className="tit_02 Montserrat tit type_02 mb_48">Quick Consultation </h3>
            </div>
            <form onSubmit={handleSubmit} className="wrap">
               <fieldset className="inquiry_fieldset">
                  <legend className="hide">Quick Consultation</legend>
                  <div className="input_box_wrap">
                     <div className="dep">
                        <input
                           className="input_box"
                           type="text"
                           id="name"
                           name="name"
                           value={formData.name}
                           onChange={handleChange}
                           required
                        />
                        <p className="txt_overlay fs_type_08 fw_type_04">성함 <span className="point">*</span></p>
                     </div>
                     <div className="dep">
                        <input
                           className="input_box"
                           type="tel"
                           id="contact"
                           name="phone"
                           value={formData.phone}
                           onChange={handleChange}
                           required
                        />
                        <p className="txt_overlay fs_type_08 fw_type_04">연락처 <span className="point">*</span></p>
                     </div>
                  </div>
                  <div className="input_box_wrap">
                     <input
                        className="input_box"
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                     />
                     <p className="txt_overlay fs_type_08 fw_type_04">이메일 <span className="point">*</span></p>
                  </div>
                  <div className="input_box_wrap">
                     <textarea
                        className="input_box"
                        type="text"
                        id="inquiry"
                        name="inquiry_content"
                        value={formData.inquiry_content}
                        onChange={handleChange}
                        required
                     />
                     <p className="txt_overlay fs_type_08 fw_type_04">문의내용 <span className="point">*</span></p>
                  </div>
                  <div className="input_box_wrap consent">
                     <div className="txt_wrap">
                        <p className="fs_type_07 fw_type_03">개인정보 수집, 이용에 동의 하십니까?</p>
                        <button onClick={() => { openPrivacyAgreement() }} className="view_all fs_type_07 fw_type_03" type="button">전문보기</button>
                     </div>
                     <div className="consent_input_wrap">
                        <div>
                           <input
                              id="disagree"
                              type="radio"
                              name="consent"
                              value="0"
                              checked={formData.consent === '0'}
                              onChange={handleChange}
                              required
                           />
                           <label htmlFor="disagree">동의하지 않습니다.</label>
                        </div>
                        <div>
                           <input
                              id="agree"
                              type="radio"
                              name="consent"
                              value="1"
                              checked={formData.consent === '1'}
                              onChange={handleChange}
                              required
                           />
                           <label htmlFor="agree">동의합니다.</label>
                        </div>
                     </div>
                  </div>
                  <div className="mt_40">
                     <button className="btn type_01 fs_type_07 fw_type_04" type="submit">문의 등록</button>
                  </div>
               </fieldset>
            </form>
         </section>
         <PrivacyAgreement
            show={privacyShow}
            close={closePrivacy}
         />
      </main>
   );
}