// import KakaoMap from "../../KakaoMap"
import SubPageMb from "../../components/SubPageMb"

export default function ContactUs() {
   return (
      <div className="contact_us sub_page company">
         <SubPageMb
            title="COMPANY"
            navDep_01="COMPANY"
            navDep_02="Contact us"
         />
         <section>
            <h3 className="tit type_01 fs_type_06 fw_type_04 txt_align_center">CONTACT US</h3>
            <h2 className="tit type_04 fs_type_12 fw_type_04 txt_align_center">오시는 길</h2>
            <div className="map_wrap">
               <div className="map">
                  <iframe id="iframe_map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4565.2926246270135!2d127.09819073436937!3d37.33876384523878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b591dc9ead55f%3A0xe8bdb14a52770ccc!2zVe2DgOybjA!5e0!3m2!1sko!2skr!4v1716509737285!5m2!1sko!2skr" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="google map"></iframe>
               </div>
               <div className="contact_info_wrap wrap">
                  <div className="contact_info">
                     <h4 className="tit fs_type_02 fw_type_03">ZeaTech</h4>
                     <ul>
                        <li className="list_item">
                           <i className="xi-maker icon"></i>
                           <p className="txt fs_type_07">경기도 용인시 수지구 신수로 767 유타워 B-108</p>
                        </li>
                        <li className="list_item">
                           <i className="xi-call icon"></i>
                           <p className="txt fs_type_07">031-266-1810</p>
                        </li>
                        <li className="list_item">
                           <i className="xi-mail icon"></i>
                           <p className="txt fs_type_07">sales@zeatech.co.kr</p>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </section>
      </div>
   )
}