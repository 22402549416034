import React, { useEffect, useState } from "react";
import SubPageMb from "../../components/SubPageMb";
import { Link, useLocation, useNavigate } from "react-router-dom";

// components Start
import PageControl from "../../components/PageControl";
// components End

// Images Start
import iconNotice from "../../images/customer/icon_notice.webp";
import axios from "axios";
// Images End

const useQuery = () => {
   return new URLSearchParams(useLocation().search);
};

export default function NoticeMain() {

   const query = useQuery();
   const page = query.get('page');
   const search = query.get('search');

   const [data, setData] = useState();
   const [pageLink, setPageLink] = useState(page ? page : 1);

   const [searchTerm, setSearchTerm] = useState(search ? search : '');
   const [searchTermWrap, setSearchTermWrap] = useState(search ? search : '');
   const [activeIndex, setActiveIndex] = useState(page ? page : 1);

   const navigate = useNavigate();

   const handleChange = (event) => {
      setSearchTermWrap(event.target.value);
   };

   const handleSubmit = (event) => {
      event.preventDefault();
      const regex = /^[a-zA-Z0-9가-힣\s]*$/;

      if (!regex.test(searchTermWrap)) {
         alert("영문[대소문자], 한글[자음+모음], 숫자로 입력해주세요");
         return;
      }

      setActiveIndex(1);
      setPageLink(1)
      setSearchTerm(searchTermWrap);
      navigate(`?page=${pageLink}&search=${searchTermWrap}`);
   };

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/notice/list?page=${pageLink}&search=${searchTerm}`);
            setData(response.data.data);
            if (response.data.data.state === false) {
               alert("일시적인 오류가 발생했습니다.");
            }
         } catch (error) {
            console.log(error);
         }
      };


      fetchData();
   }, [searchTerm, pageLink]);

   useEffect(() => {
      navigate(`?page=${pageLink}&search=${searchTerm}`);
  }, [searchTerm, pageLink, navigate]);

   return (
      <div className="customer_notice customer sub_page">
         <SubPageMb
            title="CUSTOMER"
            navDep_01="CUSTOMER"
            navDep_02="공지사항"
         />
         <div className="wrap">
            <div className="top_nav type_01">
               <h3 className="tit fs_type_12">공지사항</h3>
               <form className="search_from" onSubmit={handleSubmit}>
                  <fieldset>
                     <legend className="hide">검색</legend>
                     <div className="search_box">
                        <input
                           id="search"
                           type="search"
                           name="search"
                           placeholder="검색어를 입력하세요"
                           value={searchTermWrap}
                           onChange={handleChange}
                        />
                        <button onClick={handleSubmit} type="button">
                           <i className="xi-search icon_search" />
                        </button>
                     </div>
                  </fieldset>
               </form>
            </div>
            <ul className="list_wrap">
               {
                  data ? (
                     data.total === 0 ? (
                        <li className="item noitem txt_align_center">게시글이 없습니다.</li>
                     ) : (
                        data.list.data.map((list, index) => {
                           const dateArray = list.format_created_at.split('-');
                           return (
                              list.option_important === 0 ? (
                                 <li key={index} className="item" data-id={`${index}`}>
                                    <Link to={`detail/${list.id}?index=${list.id}&page=${pageLink}&search=${searchTerm}`} className="a_btn">
                                       <img src={iconNotice} className="notice_wrap types on" alt="" />
                                       <div className="date_wrap types"></div>
                                       <p className="tit fs_type_06">{list.title}</p>
                                       <div className="icon_wrap type_01 pc">
                                          <i className="icon xi-angle-right fs_type_11"></i>
                                       </div>
                                    </Link>
                                 </li>
                              ) : (
                                 <li key={index} className="item" data-id={`${index}`}>
                                    <Link to={`detail/${list.id}?index=${list.id}&page=${pageLink}&search=${searchTerm}`} className="a_btn">
                                       <img src={iconNotice} className="notice_wrap types" alt="" />
                                       <div className="date_wrap types on">
                                          {dateArray.map((datePart, index) => (
                                             <p key={index} className={`txt fw_type_light ${index === 0 ? "year fs_type_11" : index === 1 ? "month fs_type_11" : "date fs_type_13 fw_type_04"}`}>{datePart}</p>
                                          ))}
                                       </div>
                                       <p className="tit fs_type_06">{list.title}</p>
                                       <div className="icon_wrap type_01 pc">
                                          <i className="icon xi-angle-right fs_type_11"></i>
                                       </div>
                                    </Link>
                                 </li>
                              )
                           )
                        })
                     )
                  ) : (
                     <div className="icon_loading_wrap">
                        <i className="xi-spinner-2 xi-spin icon_loading"></i>
                     </div>
                  )
               }
            </ul>
            {data &&
               <PageControl
                  data={data}
                  pageLink={pageLink}
                  setPageLink={setPageLink}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
               />}
         </div>
      </div>
   );
}
