import SubPageMb from "../../components/SubPageMb";

// images Start
import AboutBanner from "../../images/company/about_banner.webp"
import ContactUs from "../../components/Contactus"
import CoreValue_01 from "../../images/company/core_value_01.webp"
import CoreValue_02 from "../../images/company/core_value_02.webp"
import CoreValue_03 from "../../images/company/core_value_03.webp"
import CoreValue_04 from "../../images/company/core_value_04.webp"
import CoreValue_05 from "../../images/company/core_value_05.webp"
// images End

export default function About() {

   return (
      <div className="about sub_page company">
         <SubPageMb
            title="COMPANY"
            navDep_01="COMPANY"
            navDep_02="About ZeaTech"
         />
         <section className="wrap_01 type_01 wrap">
            <h3 className="tit type_01 fs_type_06 fw_type_04">ABOUT ZeaTech</h3>
            <h2 className="tit type_02 type_03 lh_type_08 fs_type_12 fw_type_04">Leader of Premium PPE</h2>
            <p className="txt fs_type_07 lh_type_03">
               제아테크는 Heat & Flame Resistant Textile, High Performance PPE, Advanced Materials 산업을 선도하겠습니다.
            </p>
            <img className="banner_img" src={AboutBanner} alt="" />
         </section>
         <section className="wrap_01 wrap">
            <h3 className="tit type_01 fs_type_06 fw_type_04 txt_align_center">핵심가치</h3>
            <h2 className="tit type_04 fs_type_12 fw_type_04 txt_align_center">제아테크가 추구하는 가치</h2>
            <ul className="list_wrap">
               <li className="list_item">
                  <img src={CoreValue_01} alt="" />
               </li>
               <li className="list_item">
                  <img src={CoreValue_02} alt="" />
               </li>
               <li className="list_item">
                  <img src={CoreValue_03} alt="" />
               </li>
               <li className="list_item">
                  <img src={CoreValue_04} alt="" />
               </li>
               <li className="list_item">
                  <img src={CoreValue_05} alt="" />
               </li>
            </ul>
         </section>
         <ContactUs />
      </div>
   );
}