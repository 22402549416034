import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Aside({ LogoBlack, asideClose }) {

   const location = useLocation();
   useEffect(() => {
      const aside_wrap = document.querySelector(".aside_wrap")
      aside_wrap.classList.remove("on")
   }, [location])

   const [openMenus, setOpenMenus] = useState({});

   const toggleMenu = (index) => {
      setOpenMenus((prev) => {
         const isOpen = !!prev[index];
         return {
            ...prev,
            [index]: !isOpen
         };
      });
   };
   return (
      <div className="aside_wrap">
         <aside id="aside">
            <button onClick={asideClose} className="btn_close xi-close-thin fs_type_13"></button>
            <div className="contents_wrap">
               <div className="logo_wrap">
                  <img className="logo white" src={LogoBlack} alt="logo" />
                  <h2 className="fs_type_14">ZeaTech</h2>
               </div>
               <ul className="item_wrap">
                  <li className="item">
                     <button className="btn main" onClick={() => toggleMenu(0)}>
                        <h3 className="fs_type_05 fw_type_04">COMPANY</h3>
                        <i className={`icon_arrow xi-angle-down fs_type_05 ${openMenus[0] ? 'on' : ''}`}></i>
                     </button>
                     {/* <ul className="btn_sub_wrap"> */}
                     <ul className={`btn_sub_wrap ${openMenus[0] ? 'open' : ''}`}>
                        <li>
                           <Link className="btn sub fs_type_08" to="/company/about">About ZeaTech</Link>
                        </li>
                        <li>
                           <Link className="btn sub fs_type_08" to="/company/history">연혁</Link>
                        </li>
                        <li>
                           <Link className="btn sub fs_type_08" to="/company/contact-us">오시는 길</Link>
                        </li>
                     </ul>
                  </li>
                  <li className="item">
                     <Link to="/product" className="btn main">
                        <h3 className="fs_type_05 fw_type_04">PRODUCT</h3>
                     </Link>
                  </li>
                  <li className="item">
                     <button className="btn main" onClick={() => toggleMenu(1)}>
                        <h3 className="fs_type_05 fw_type_04">CUSTOMER</h3>
                        <i className={`icon_arrow xi-angle-down fs_type_05 ${openMenus[1] ? 'on' : ''}`}></i>
                     </button>
                     {/* <ul className="btn_sub_wrap"> */}
                     <ul className={`btn_sub_wrap ${openMenus[1] ? 'open' : ''}`}>
                        <li>
                           <Link className="btn sub fs_type_08" to="/customer/notice">공지사항</Link>
                        </li>
                        <li>
                           <Link className="btn sub fs_type_08" to="/customer/data">자료실</Link>
                        </li>
                     </ul>
                  </li>
                  <li className="item">
                     <button className="btn main" onClick={() => toggleMenu(2)}>
                        <h3 className="fs_type_05 fw_type_04">CONTACT</h3>
                        <i className={`icon_arrow xi-angle-down fs_type_05 ${openMenus[2] ? 'on' : ''}`}></i>
                     </button>
                     {/* <ul className="btn_sub_wrap"> */}
                     <ul className={`btn_sub_wrap ${openMenus[2] ? 'open' : ''}`}>
                        <li>
                           <Link className="btn sub fs_type_08" to="/contact/faq">자주묻는 질문</Link>
                        </li>
                        <li>
                           <Link className="btn sub fs_type_08" to="/contact/inquire">문의하기</Link>
                        </li>
                     </ul>
                  </li>
               </ul>
            </div>
         </aside>
      </div>
   )
}