import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from 'swiper/modules';
import axios from "axios";

// components Start
import SubPageMb from "../../components/SubPageMb";
import PageControl from "../../components/PageControl";
// components End

// css Start

// css End

const useQuery = () => {
   return new URLSearchParams(useLocation().search);
};

export default function ProductMain() {

   const query = useQuery();
   const page = query.get('page');
   const search = query.get('search');
   const query_order = query.get('order');
   const query_order2 = query.get('order2');
   const query_order3 = query.get('order3');

   const query_activeBtn01 = query.get('activeBtn01') ? parseInt(query.get('activeBtn01')) : -1;
   const query_activeBtn02 = query.get('activeBtn02') ? parseInt(query.get('activeBtn02')) : -1;
   const query_activeBtn03 = query.get('activeBtn03') ? parseInt(query.get('activeBtn03')) : -1;

   // fetch data Start
   const [data, setData] = useState();

   const [order1, setOrder1] = useState(query_order ? query_order : 0);
   const [order2, setOrder2] = useState(query_order2 ? query_order2 : 0);
   const [order3, setOrder3] = useState(query_order3 ? query_order3 : 0);
   const [searchTerm, setSearchTerm] = useState(search ? search : '');
   const [searchTermWrap, setSearchTermWrap] = useState(search ? search : '');
   const [pageLink, setPageLink] = useState(page ? page : 1);
   const [activeIndex, setActiveIndex] = useState(1);

   const navigate = useNavigate();

   const handleChange = (event) => {
      setSearchTermWrap(event.target.value);
      setPageLink(1);
      setActiveIndex(1);
   };

   const handleSubmit = (event) => {
      event.preventDefault();
      const regex = /^[a-zA-Z0-9가-힣\s]*$/;

      if (!regex.test(searchTermWrap)) {
         alert("영문[대소문자], 한글[자음+모음], 숫자로 입력해주세요");
         return;
      }

      setSearchTerm(searchTermWrap);
      navigate(`?page=${pageLink}&search=${searchTermWrap}&?order=${order1}&order2=${order2}&order3=${order3}`);
   };

   useEffect(() => {
      if (page) {
         setActiveIndex(parseInt(page));
      }
   }, [page]);

   useEffect(() => {
      const fetchData = async () => {
         try {
            let url = `${process.env.REACT_APP_API_URL}/api/product/list?order=${order1}&order2=${order2}&order3=${order3}&page=${pageLink}&search=${searchTerm}`
            const response = await axios.get(url)
            setData(response.data.data)
            if (response.data.data.state === false) {
               alert("일시적인 오류가 발생했습니다.")
            }
         } catch (error) {
            console.log(error)
         }
      }

      fetchData();
   }, [order1, order2, order3, searchTerm, pageLink])
   // fetch data End

   const [activeBtn01, setActiveBtn01] = useState(query_activeBtn01);
   const [activeBtn02, setActiveBtn02] = useState(query_activeBtn02);
   const [activeBtn03, setActiveBtn03] = useState(query_activeBtn03);

   const handleClick01 = (topCategory, index) => {
      setActiveBtn01(index);
      setOrder1(index === -1 ? 0 : topCategory.id);
      setOrder2(0);
      setOrder3(0);
      setActiveBtn02(-1);
      setActiveBtn03(-1);
      setPageLink(1);
      setActiveIndex(1);
   };
   const handleClick02 = (midCategory, index) => {
      setActiveBtn02(index)
      setOrder2(index === -1 ? 0 : midCategory.id);
      setOrder3(0);
      setActiveBtn03(-1)
      setPageLink(1);
      setActiveIndex(1);
   }
   const handleClick03 = (botCategory, index) => {
      setActiveBtn03(index)
      setOrder3(index === -1 ? 0 : botCategory.id);
      setPageLink(1);
      setActiveIndex(1);
   }

   return (
      <div className="product sub_page">
         <SubPageMb
            title="PRODUCT"
            navDep_01="PRODUCT"
         />
         <div className="wrap">
            <div className="top_nav type_01">
               <h3 className="fs_type_12 tit">제품소개</h3>
               <form className="search_from" onSubmit={handleSubmit}>
                  <fieldset>
                     <legend className="hide">검색</legend>
                     <div className="search_box">
                        <input
                           id="search"
                           type="search"
                           name="search"
                           placeholder="검색어를 입력하세요"
                           value={searchTermWrap}
                           onChange={handleChange}
                        />
                        <button onClick={handleSubmit} type="button">
                           <i className="xi-search icon_search" />
                        </button>
                     </div>
                  </fieldset>
               </form>
            </div>
            <div className="category_wrap">
               <div className="category_dep_01_wrap">
                  <h4 className="tit">SEGMENT</h4>
                  <Swiper
                     className="category_dep_01"
                     slidesPerView={'auto'}
                     spaceBetween={8}
                     freeMode={true}
                     modules={[FreeMode]}
                  >
                     <SwiperSlide key="all">
                        <div
                           className={`category_btn dep_01_item fs_type_08 fw_type_light ${activeBtn01 === -1 ? "on" : ""}`}
                           onClick={() => handleClick01({ id: 0 }, -1)}
                        >
                           <i className="xi-plus-min"></i>
                           <p>전체</p>
                        </div>
                     </SwiperSlide>
                     {data ? (
                        data.topCategory.map((topCategory, index) => (
                           <SwiperSlide key={index}>
                              <div
                                 className={`category_btn dep_01_item fs_type_08 fw_type_light ${index === activeBtn01 ? "on" : ""}`}
                                 onClick={() => handleClick01(topCategory, index)}
                              >
                                 <i className="xi-plus-min"></i>
                                 <p>{topCategory.title}</p>
                              </div>
                           </SwiperSlide>
                        ))
                     ) : (
                        <div className="loading">Loading ...</div>
                     )}
                  </Swiper>
               </div>

               <Swiper
                  slidesPerView={'auto'}
                  spaceBetween={40}
                  freeMode={true}
                  modules={[FreeMode]}
                  className="category_dep_02"
               >
                  <SwiperSlide key="all">
                     <button
                        className={`category_btn dep_02_item fs_type_08 ${activeBtn02 === -1 ? "on" : ""}`}
                        onClick={() => handleClick02({ id: 0 }, -1)}
                     >
                        전체
                     </button>
                  </SwiperSlide>
                  {data ? (
                     data.midCategory.map((midCategory, index) => (
                        <SwiperSlide key={index}>
                           <div
                              className={`category_btn dep_02_item fs_type_08 ${index === activeBtn02 ? "on" : ""}`}
                              onClick={() => handleClick02(midCategory, index)}
                           >
                              {midCategory.title}
                           </div>
                        </SwiperSlide>
                     ))
                  ) : (
                     <div className="loading">Loading ...</div>
                  )
                  }
               </Swiper>
               {data ? (
                  <Swiper
                     slidesPerView={'auto'}
                     spaceBetween={40}
                     freeMode={true}
                     modules={[FreeMode]}
                     className="category_dep_03"
                  >
                     <SwiperSlide key="all">
                        <button
                           className={`category_btn dep_03_item fs_type_08 ${activeBtn03 === -1 ? "on" : ""}`}
                           onClick={() => handleClick03({ id: 0 }, -1)}
                        >
                           전체
                        </button>
                     </SwiperSlide>
                     {
                        data.botCategory.map((botCategory, index) => (
                           <SwiperSlide key={index}>
                              <div
                                 className={`category_btn dep_03_item fs_type_08 ${index === activeBtn03 ? "on" : ""}`}
                                 onClick={() => handleClick03(botCategory, index)}
                              >
                                 {botCategory.title}
                              </div>
                           </SwiperSlide>
                        ))
                     }
                  </Swiper>) : (
                  <div className="loading">Loading ...</div>
               )}
               <div className="category_result_wrap mt_40">
                  <h4 className="tit fs_type_06 fw_type_light">총 <span className="point fw_type_03">{data && data.total}</span>개의 게시물</h4>
                  <ul className="category_result mt_24">
                     {data ? (
                        data.data.map((product, index) => {
                           // let windowWidth = document.body.clientWidth
                           // let renderLine = false;
                           // if (windowWidth >= 768) {
                           //    if ((index + 1) % 5 === 0 && index >= 9 && index < product.length - 1) {
                           //       renderLine = true;
                           //    }
                           //    // 두 줄 이상일 때 첫 번째 줄 끝에도 .line을 생성
                           //    if (index === 4 && product.length > 5 && index < product.length - 1) {
                           //       renderLine = true;
                           //    }
                           // } else {
                           //    if ((index + 1) % 2 === 0 && index >= 3 && index < product.length - 1) {
                           //       renderLine = true;
                           //    }
                           //    // 두 줄 이상일 때 첫 번째 줄 끝에도 .line을 생성
                           //    if (index === 1 && product.length > 2 && index < product.length - 1) {
                           //       renderLine = true;
                           //    }
                           // }
                           return (
                              <React.Fragment key={index}>
                                 <li className="result_item">
                                    <div className="product_img_wrap mb_16">
                                       <img className="product_img" src={product.file} alt="" />
                                    </div>
                                    <div>
                                       <p className="txt_align_center lh_type_06 fs_type_09 selected_category">{product.topCategory}</p>
                                       <h4 className="txt_align_center tit lh_type_07 fs_type_11">{product.product_name}</h4>
                                    </div>
                                    <Link to={`detail/${product.id}?index=${product.id}&order=${order1}&order2=${order2}&order3=${order3}&page=${pageLink}&search=${searchTerm}&activeBtn01=${activeBtn01}&activeBtn02=${activeBtn02}&activeBtn03=${activeBtn03}`} className="a_btn txt_align_center mt_24">View more</Link>
                                 </li>
                                 {/* {renderLine && <div className="line"></div>} */}
                              </React.Fragment>
                           )
                        })
                     ) : (
                        <div className="loading">Loading ...</div>
                     )
                     }
                  </ul>
                  {data ? (
                     <PageControl
                        data={data}
                        setPageLink={setPageLink}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                     />
                  ) : (
                     <div className="loading">Loading ...</div>
                  )}
               </div>
            </div>
         </div>
      </div>
   )
}
