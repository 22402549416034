import { React, useEffect, useState } from "react";
import { Routes, Route, Outlet } from "react-router-dom";

// components Start
import ScrollToTop from "./components/scrollToTop";
import Header from "./components/Header";
import Footer from "./components/Footer";
import TopBtn from "./components/TopBtn";
import Aside from "./components/Aside";
// components End

// pages Start
import Home from "./pages/Home";
import Company from "./pages/company/Company";
import About from "./pages/company/About";
import History from "./pages/company/History";
import ContactUs from "./pages/company/ContactUs";
import Product from "./pages/product/Product";
import ProductIntro from "./pages/product/ProductIntro"
import ProductDetail from "./pages/product/ProductDetail";
import Customer from "./pages/customer/Customer";
import Contact from "./pages/contact/Contact";
import Notice from "./pages/customer/Notice";
import NoticeMain from "./pages/customer/NoticeMain";
import NoticeDetail from "./pages/customer/NoticeDetail";
import Data from "./pages/customer/Data";
import DataMain from "./pages/customer/DataMain";
import DataDetail from "./pages/customer/DataDetail";
import Inquire from "./pages/contact/Inquire";
import Faq from "./pages/contact/Faq";
import NotFound from "./NotFound";
// pages End

// css Start
import 'nprogress/nprogress.css';
import './css/bootstrap.3.4.1.css'
import './css/common.css'
import './css/index.css'
// css End

// images Start
import LogoBlack from "./images/logo_black.webp";
import LogoWhite from "./images/logo_white.webp";
// images End

export default function App() {

   const [asideWrap, setAsideWrap] = useState();
   ScrollToTop();

   useEffect(() => {

      const asideElement = document.querySelector(".aside_wrap");
      setAsideWrap(asideElement)

   }, [])

   const asideOpen = () => {
      if (asideWrap) {
         asideWrap.classList.add("on")
      }
   }
   const asideClose = () => {
      if (asideWrap) {
         asideWrap.classList.remove("on")
      }
   }

   return (
      <div>
         <Header
            LogoWhite={LogoWhite}
            LogoBlack={LogoBlack}
            AsideOpen={asideOpen}
         />

         <Outlet />
         <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/company" element={<Company />}>
               <Route index element={<NotFound />} />
               <Route path="about" element={<About />} />
               <Route path="history" element={<History />} />
               <Route path="contact-us" element={<ContactUs />} />
            </Route>
            <Route path="/product" element={<Product />}>
               <Route index element={<ProductIntro />} />
               <Route path="detail/:id" element={<ProductDetail />} />
            </Route>
            <Route path="/customer" element={<Customer />}>
               <Route index element={<NotFound />} />
               <Route path="notice" element={<Notice />}>
                  <Route index element={<NoticeMain />} />
                  <Route path="detail/:id" element={<NoticeDetail />} />
               </Route>
               <Route path="data" element={<Data />}>
                  <Route index element={<DataMain />} />
                  <Route path="detail/:id" element={<DataDetail />} />

               </Route>
            </Route>
            <Route path="/contact" element={<Contact />}>
               <Route index element={<NotFound />} />
               <Route path="faq" element={<Faq />} />
               <Route path="inquire" element={<Inquire />} />
            </Route>
            <Route path="*" element={<NotFound />} />
         </Routes>

         <Footer
            LogoWhite={LogoWhite}
         />
         <TopBtn />
         <Aside
            LogoBlack={LogoBlack}
            asideClose={asideClose}
         />
      </div>
   );
}