import { useEffect } from "react";

export default function TopBtn() {

   useEffect(() => {

      const topScrollBtn = document.querySelector('.Top_Scroll_btn');

      topScrollBtn.addEventListener('click', function () {
         window.scrollTo({ top: 0, behavior: 'smooth' });
      });

      document.addEventListener('scroll', function () {
         if (document.documentElement.scrollTop > 88) {
            topScrollBtn.classList.add('active');
         } else {
            topScrollBtn.classList.remove('active');
         }

         const scrollPercent = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
         const color = getColorFromPercentage(scrollPercent);

         const progressBar = topScrollBtn.querySelector('.progress_bar');
         if (progressBar) {
            progressBar.style.background = `conic-gradient(${color} 0% ${scrollPercent}%, #fff ${scrollPercent}% 100%)`;
         }
      });

      function getColorFromPercentage(percentage) {
         const startColor = [0, 0, 0]; // RGB 값으로 작성해야함!
         const endColor = [0, 0, 0];

         const color = startColor.map((start, i) => {
            return Math.round(start + (endColor[i] - start) * (percentage / 100));
         });

         return `rgb(${color.join(',')})`;
      }
   }, [])

   return (
      <div className="top_btn_wrap">
         <button className="Top_Scroll_btn">
            <i className="xi-arrow-up"></i>
            <div className="progress_bar"></div>
         </button>
      </div>
   )
}