import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import axios from "axios"
import DOMPurify from "dompurify"

// conponents Start
import SubPageMb from "../../components/SubPageMb"
import PageControl from "../../components/PageControl"
// conponents End

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export default function Faq() {
    const query = useQuery();
    const page = query.get('page');
    const search = query.get('search');

    const [data, setData] = useState();
    const [pageLink, setPageLink] = useState(page ? page : 1);

    const [searchTerm, setSearchTerm] = useState(search ? search : '');
    const [searchTermWrap, setSearchTermWrap] = useState(search ? search : '');
    const [activeIndex, setActiveIndex] = useState(page ? page : 1);

    const navigate = useNavigate();

    const handleChange = (event) => {
        setSearchTermWrap(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const regex = /^[a-zA-Z0-9가-힣\s]*$/;

        if (!regex.test(searchTermWrap)) {
            alert("영문[대소문자], 한글[자음+모음], 숫자로 입력해주세요");
            return;
        }

        setActiveIndex(1);
        setPageLink(1)
        setSearchTerm(searchTermWrap);
        navigate(`?page=${pageLink}&search=${searchTermWrap}`);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/faq/list?page=${pageLink}&search=${searchTerm}`);
                setData(response.data.data);
                if (response.data.data.state === false) {
                    alert("일시적인 오류가 발생했습니다.");
                }
            } catch (error) {
                console.log(error);
            }
        };


        fetchData();
    }, [searchTerm, pageLink]);

    useEffect(() => {
        navigate(`?page=${pageLink}&search=${searchTerm}`);
    }, [searchTerm, pageLink, navigate]);

    // List
    const [listActiveIndex, setListActiveIndex] = useState();
    const handelToggle = (index) => {
        setListActiveIndex(listActiveIndex === index ? null : index)
    }

    return (
        <div className="contact_faq contact sub_page">
            <SubPageMb
                title="CONTACT"
                navDep_01="CONTACT"
                navDep_02="자주묻는 질문"
            />
            <div className="wrap">
                <div className="top_nav type_01">
                    <h3 className="tit fs_type_12">자주묻는질문</h3>
                    <form className="search_from" onSubmit={handleSubmit}>
                        <fieldset>
                            <legend className="hide">검색</legend>
                            <div className="search_box">
                                <input
                                    id="search"
                                    type="search"
                                    name="search"
                                    placeholder="검색어를 입력하세요"
                                    value={searchTermWrap}
                                    onChange={handleChange}
                                />
                                <button type="submit">
                                    <i className="xi-search icon_search" />
                                </button>
                            </div>
                        </fieldset>
                    </form>
                </div>
                {data ? (
                    data.total === 0 ? (
                        <div className="item noitem txt_align_center">게시글이 없습니다.</div>
                    ) : (
                        data.list.data.map((data, index) => (
                            <div key={index} className="list_wrap">
                                <div onClick={() => handelToggle(index)} className="list item question">
                                    <p className="tit fs_type_02 fw_type_04">Q</p>
                                    <p className="txt fs_type_06 fw_type_light lh_type_07">{data.question}</p>
                                    <i className={`icon xi-angle-down-thin fs_type_02 pc ${listActiveIndex === index ? 'on' : ''}`}></i>
                                </div>
                                <div className={`item item_answer ${listActiveIndex === index ? 'on' : ''}`}>
                                    <p className="tit fs_type_02 fw_type_04">A</p>
                                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.answer) }} className="txt fs_type_06 fw_type_light lh_type_07"></p>
                                </div>
                            </div>
                        ))
                    )
                ) : (
                    <div className="icon_loading_wrap">
                        <i className="xi-spinner-2 xi-spin icon_loading"></i>
                    </div>
                )}
                {data &&
                    <PageControl
                        data={data}
                        pageLink={pageLink}
                        setPageLink={setPageLink}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                    />}
            </div>
        </div>
    )
}