import { Link } from "react-router-dom";

export default function ContactUs() {
   return (
      <section className="contactus banner">
         <h2 className="tit fw_type_04 txt_align_center">
            당신의 안전을 책임지는 제아테크 <br className="pc" />
            제아테크에서 맞춤 상담을 받아보세요.
         </h2>
         <p className="txt fs_type_07 txt_align_center">비즈니스 환경과 개인의 특성에 맞춰 맞춤 상담을 제공해드립니다.</p>
         <Link to="/contact/inquire" className="a_btn">
            <p className="fs_type_07">CONTACT US</p>
            <i className="xi-long-arrow-right"></i>
         </Link>
      </section>
   )
}