import { useEffect } from "react"
import { Link } from "react-router-dom"

export default function NotFound() {

   useEffect(() => {
      let header = document.getElementById("header")
      let headerHeight = header.clientHeight

      let notfoundPage = document.querySelector('.notfound')
      notfoundPage.style.padding = `${headerHeight}px`

      header.classList.add("on")

      return () => {
         header.classList.remove("on");
      };
   }, [])

   return (
      <div className="notfound">
         <div className="txt_wrap">
            <h1 className="tit fs_type_01 mb_16">
               페이지를 찾을 수 없어요
            </h1>
            <p className="txt fs_type_06 lh_type_04 mb_48">
               방문하시려는 주소가 잘못되었거나,<br />
               페이지가 변경 또는 삭제된 것 같아요.
            </p>
            <Link to="/" className="btn_a type_01">
               <p className="txt">홈으로</p>
               <i className="xi-long-arrow-right"></i>
            </Link>
         </div>
      </div>
   )
}