import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import DOMPurify from 'dompurify';

// components Start
import HeaderActive from "../../components/headerActive";
import ContactUs from "../../components/Contactus";
import DetailControl from "../../components/DetailControl";
// components End

const useQuery = () => {
   return new URLSearchParams(useLocation().search);
};

export default function ProductDetail() {

   const [data, setData] = useState();
   const [loading, setLoading] = useState(true);

   const query = useQuery();
   const index = query.get('index');
   const search = query.get('search');

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/notice/detail?index=${index}&search=${search}`)
            setData(response.data.data)
            setLoading(false);
         } catch (error) {
            setLoading(false);
            console.log(error)
         }
      }

      fetchData();
   }, [index, search])

   useEffect(() => {
      const contentsWraps = document.querySelectorAll('.contents_wrap.type_01');

      contentsWraps.forEach((wrap) => {
         const iframes = wrap.querySelectorAll('iframe');
         iframes.forEach((iframe) => {
            // 새로운 div 태그 생성
            const iframeContainer = document.createElement('div');
            iframeContainer.className = 'iframe_container';

            // iframe의 부모 노드에 iframeContainer를 삽입
            iframe.parentNode.insertBefore(iframeContainer, iframe);

            // iframe을 iframeContainer 안으로 이동
            iframeContainer.appendChild(iframe);
         });
      });
   }, [data]);

   HeaderActive();

   if (loading) return <div>Loading...</div>;

   return (
      <div className="product_detail sub_page mt_130">
         <div className="info_wrap type_01 wrap mb_40">
            <h3 className="tit fs_type_05 fw_type_03 txt_align_center">{data.title}</h3>
            <div className="sub_info_wrap mt_24">
               <div className="date_wrap">
                  <p className="tit fs_type_08 fw_type_03">Date</p>
                  <p className="txt fs_type_08 fw_type_light">{data.format_created_at}</p>
               </div>
               <div className="views_wrap">
                  <p className="tit fs_type_08 fw_type_03">Views</p>
                  <p className="txt fs_type_08 fw_type_light">{data.view}</p>
               </div>
            </div>
         </div>
         <div className="contents_wrap type_01 wrap">
            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.content) }}></p>
            {data.file_addr && (
               <a href={`${process.env.REACT_APP_API_URL}/api/get/file/${data.time_file_name}/notice`} className="btn_download">
                  <i className="icon xi-folder-open fs_type_11"></i>
                  <p className="txt fs_type_11 fw_type_03 file_name">{data.file_name}</p>
                  <i className="icon icon_download xi-download fs_type_11"></i>
               </a>
            )}
         </div>
         <DetailControl
            navigationData={data}
            listUrl={"/customer/notice"}
            contextType={"notice"}
         />
         <ContactUs />
      </div>
   )
}