import { Link, useLocation } from "react-router-dom";

export default function SubPageMb({ title, navDep_01, navDep_02 }) {

   const location = useLocation();
   const pathName = location.pathname;

   // Nav Items
   const companyNavItems = [
      { path: "/company/about", gnbItem: "About ZeaTech" },
      { path: "/company/history", gnbItem: "연혁" },
      { path: "/company/contact-us", gnbItem: "오시는 길" }
   ]
   const productNavItems = [
      { path: "/product", gnbItem: "Product" }
   ]
   const customerNavItems = [
      { path: "/customer/notice", gnbItem: "공지사항" },
      { path: "/customer/data", gnbItem: "자료실" }
   ]
   const contactNavItems = [
      { path: "/contact/faq", gnbItem: "자주묻는 질문" },
      { path: "/contact/inquire", gnbItem: "문의하기" }
   ]

   // NavItems를 url에 맞게 
   let navItems = null;
   if (pathName.startsWith('/company')) {
      navItems = companyNavItems;
   } else if (pathName.startsWith('/product')) {
      navItems = productNavItems;
   } else if (pathName.startsWith('/customer')) {
      navItems = customerNavItems;
   } else if (pathName.startsWith('/contact')) {
      navItems = contactNavItems;
   }

   // url 경로 깊이
   const pathSegments = location.pathname.split('/').filter(segment => segment);
   const pathDepth = pathSegments.length;

   return (
      <div className="mb_wrap">
         <div className="txt_wrap">
            <h2 className="fs_type_10 fw_type_04 tit txt_align_center">{title}</h2>
            {/* <p className="fs_type_11 txt_align_center">{subTitle}</p> */}
         </div>
         <div className="gnb">
            <ul className="nav">
               <li><i className="xi-home fs_type_08 fw_type_04"></i></li>
               <li><i className="xi-angle-right fw_type_04 arrow"></i></li>
               <li className="fs_type_08">{navDep_01}</li>
               {pathDepth >= 2 && (
                  <>
                     <li><i className="xi-angle-right fw_type_04 arrow"></i></li>
                     <li className="fs_type_08">{navDep_02}</li>
                  </>
               )}
            </ul>
            <ul className="gnb_item_wrap">
               {navItems.map((item, index) => (
                  <li key={index} className="item">
                     <Link className={`gnb_item fs_type_07 fw_type_02 ${location.pathname === item.path ? "on" : ""}`} to={item.path}>{item.gnbItem}</Link>
                  </li>
               ))}
            </ul>
         </div>
      </div>
   )
}