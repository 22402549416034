import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Scrollbar } from 'swiper/modules';
import axios from 'axios';
import SubPageMb from "../../components/SubPageMb";

// CSS Start
import 'swiper/css/scrollbar';
// CSS End

export default function History() {

   const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 768);

   useEffect(() => {
      const handleResize = () => {
         setIsWideScreen(window.innerWidth >= 768);
      };

      window.addEventListener("resize", handleResize);

      return () => {
         window.removeEventListener("resize", handleResize);
      };
   }, []);

   // fetch data Start
   const [data, setData] = useState(null);
   const [error, setError] = useState(null);

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/history`)
            setData(response.data.data)
         } catch (error) {
            setError(error);
            console.log(error)
         }
      }

      fetchData();
   }, [])

   if (error) return <div>Error: {error.message}</div>;
   // fetch data Start

   return (
      <div className="history sub_page company">
         <SubPageMb
            title="COMPANY"
            navDep_01="COMPANY"
            navDep_02="History"
         />
         <section className="wrap_01 wrap">
            <h3 className="tit type_01 fs_type_06 fw_type_04 txt_align_center">HISTORY</h3>
            <h2 className="tit type_04 fs_type_12 fw_type_04 txt_align_center">제아테크가 걸어온 길</h2>
            {data ? (
               <Swiper
                  slidesPerView={"auto"}
                  scrollbar={isWideScreen ? { hide: false, dragSize: 570 } : false}
                  freeMode={isWideScreen}
                  modules={isWideScreen ? [FreeMode, Scrollbar] : []}
                  allowTouchMove={isWideScreen}
                  className="history_swiper wrap"
               >
                  {data.map((history, index) => (
                     <SwiperSlide key={index} className='history_slide'>
                        <h3 className='tit fs_type_02'>{history.year}</h3>
                        <ul className='history_item_wrap'>
                           {history.content.map((historyContent, historyContentIndex) => (
                              <li key={historyContentIndex} className='list_item fs_type_07 lh_type_03'>
                                 {historyContent.content}
                              </li>
                           ))}
                        </ul>
                        {history.file && (
                           <img className='history_img' src={history.file} alt={`${history.year}`} />
                        )}
                     </SwiperSlide>
                  ))}
               </Swiper>) : (
               <div className='loading'>Loading...</div>
            )}
         </section>
      </div >
   );
}