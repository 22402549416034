import React, { useState } from "react"
import axios from "axios";

// components Start
import SubPageMb from "../../components/SubPageMb"
// components End

export default function Inquire() {

    // 폼 데이터 전송 Start
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        inquiry_content: '',
        inquiry_file: null,
        consent: false,
    });
    const [fileName, setFileName] = useState('');

    const handleChange = (e) => {
        const { name, value, files, type, checked } = e.target;
        if (type === 'file') {
            setFormData({
                ...formData,
                [name]: files.length > 0 ? files[0] : null,
            });
            setFileName(files.length > 0 ? files[0].name : '');
        } else if (type === 'checkbox') {
            setFormData({
                ...formData,
                [name]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.consent) {
            alert('개인정보 수집 및 이용에 동의해야 합니다.');
            return;
        }

        const data = new FormData();
        data.append('name', formData.name);
        data.append('phone', formData.phone);
        data.append('email', formData.email);
        data.append('inquiry_content', formData.inquiry_content);
        if (formData.inquiry_file) {
            data.append('inquiry_file', formData.inquiry_file);
        }
        data.append('consent', formData.consent ? '1' : '0');

        axios.post(`${process.env.REACT_APP_API_URL}/api/registration/inquiry`, data)
            .then(response => {
                if (response.data.state) {
                    alert('문의가 등록되었습니다.');
                    window.location.reload();
                } else {
                    alert(response.data.msg)
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
                alert('문의 등록에 실패했습니다.');
            });
    };
    // 폼 데이터 전송 End

    const deleteFile = () => {
        setFormData({
            ...formData,
            inquiry_file: null,
        });
        setFileName('');
    };

    return (
        <div className="contact_inquire contact sub_page">
            <SubPageMb
                title="CONTACT"
                navDep_01="CONTACT"
                navDep_02="문의하기"
            />
            <div className="wrap">
                <div className="top_head_wrap mb_40">
                    <h3 className="tit fs_type_12 fw_type_04">문의하기</h3>
                    <p className="txt fs_type_11 pc">
                        <strong className="point">* &nbsp;</strong>
                        필수 입력 항목
                    </p>
                </div>
                <form onSubmit={handleSubmit} className="inquire_from">
                    <fieldset>
                        <legend className="hide">문의내용 작성</legend>
                        <div className="item_01">
                            <div className="name_wrap input_wrap">
                                <label htmlFor="name" className="mb_16 fs_type_06 fw_type_04">성함 <strong className="point">*</strong></label>
                                <input
                                    value={formData.name}
                                    onChange={handleChange}
                                    name="name"
                                    id="name"
                                    type="text"
                                    placeholder="성함을 입력해주세요."
                                    required
                                />
                            </div>
                            <div className="phone_wrap input_wrap">
                                <label htmlFor="phone" className="mb_16 fs_type_06 fw_type_04">연락처 <strong className="point">*</strong></label>
                                <input
                                    type="tel"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    id="phone"
                                    placeholder="연락처를 입력해주세요."
                                    required
                                />
                            </div>
                        </div>
                        <div className="email_wrap input_wrap">
                            <label htmlFor="email" className="mb_16 fs_type_06 fw_type_04">이메일 <strong className="point">*</strong></label>
                            <input
                                value={formData.email}
                                onChange={handleChange}
                                name="email"
                                id="email"
                                type="email"
                                placeholder="성함을 입력해주세요."
                                required
                            />
                        </div>
                        <div className="message_wrap input_wrap">
                            <label htmlFor="message" className="mb_16 fs_type_06 fw_type_04">문의내용 <strong className="point">*</strong></label>
                            <textarea
                                value={formData.inquiry_content}
                                onChange={handleChange}
                                name="inquiry_content"
                                id="inquiry_content"
                                type="text"
                                placeholder="문의내용을 기재해주세요.(필수)"
                                required
                            />
                        </div>
                        <div className="upload_wrap input_wrap mb_40">
                            <label
                                name="inquiry_file"
                                className="label_upload fs_type_11 fw_type_light"
                                htmlFor="inquiry_file"
                            >
                                파일첨부
                            </label>
                            <input
                                id="inquiry_file"
                                className="input_upload"
                                type="file"
                                name="inquiry_file"
                                onChange={handleChange}
                            />
                            {fileName && <div className="file_name_display">{fileName}</div>}
                            {formData.inquiry_file && <button onClick={deleteFile} className="xi-close" type="button"></button>}
                        </div>
                        <div className="privacy_wrap">
                            <p className="fs_type_06 fw_type_04 mb_16">개인정보수집 동의</p>
                            <div className="info_wrap mb_16">
                                <p className="fs_type_07 fw_type_02 mb_24">개인정보 수집 • 이용 내역</p>
                                <ul className="list_wrap">
                                    <li className="item mb_16">
                                        <p className="tit fs_type_08 fw_type_light pc">항목 (필수항목)</p>
                                        <p className="txt fs_type_08 fw_type_light pc">성명, 연락처, 이메일</p>
                                    </li>
                                    <li className="item mb_16">
                                        <p className="tit fs_type_08 fw_type_light pc">수집 • 이용 목적</p>
                                        <p className="txt fs_type_08 fw_type_light">고객문의는 최소한의 개인정보만을 수집합니다. 상담내용확인 및 상담결과 회신 서비스 제공을 목적으로 개인정보 수집 이용 동의를 구합니다.</p>
                                    </li>
                                    <li className="item">
                                        <p className="tit fs_type_08 fw_type_light pc">보유기간</p>
                                        <p className="txt fs_type_08 fw_type_light">개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. (단, 타 법령에 의해 보존의무가 있는 경우 법령이 지정한 기간 동안 보존합니다.)</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="privacy_agree_wrap mb_80">
                                <input
                                    id="privacy_agree"
                                    name="consent"
                                    value="1"
                                    checked={formData.consent}
                                    onChange={handleChange}
                                    required
                                    type="checkbox"
                                />
                                <label className="fs_type_11" htmlFor="privacy_agree">개인정보 수집 및 이용에 대한 동의 (필수)</label>
                            </div>
                        </div>
                        <button className="btn_submit fs_type_07 fw_type_04" type="submit">문의하기</button>
                    </fieldset>
                </form>
            </div>
        </div>
    )
}